<template>
    <jy-dialog :title="title" :visible.sync="dialogVisible" type="table" :width="'800px'">
        <div>
            <el-row v-for="item in showList" :key="item.title">
                <div class="title">{{item.title}}</div>
                <el-col v-for="one in item.cellList" :key="one.label" :span="one.span||12" v-bind:class="{'hidden':one.isHidden}">
                    <span class="label">{{one.label}}: </span>
                    <span class="value">{{ one.value || formatterValue(one) || '-'}}{{one.unit||''}}</span>
                </el-col>
            </el-row>
        </div>
    </jy-dialog>
</template>
<script>
import { getAuditStatus, getRunStatus, getRType } from '@/common/js/wordbook.js'
let auditStatus = getAuditStatus()
let runStatus = getRunStatus()
let rType = getRType()
export default {
    data() {
        return {
            title: '完整信息',
            dialogVisible: false,
            waybillId: '',
            info: {},
            showList: [],
        }
    },
    methods: {
        init(option) {
            this.waybillId = option.waybillId
            this.dialogVisible = true
            this.info = {
                ...option
            }
            this.getDetail()
            this.setShowList()
        },
        auditStatusFil(cellVal){
            let label = ''
            auditStatus.forEach(one=>{
                if (one.value == cellVal) {
                    label = one.label
                }
            })
            return label
        },
        runStatusFil(cellVal){
            let label = ''
            runStatus.forEach(one=>{
                if (one.value == cellVal) {
                    label = one.label
                }
            })
            return label
        },
        rTypeFil(cellVal){
            let label = ''
            rType.forEach(one=>{
                if (one.value == cellVal) {
                    label = one.label
                }
            })
            return label
        },
        isCrossLFil(cellVal){
            return cellVal == '1'?'否':'是'
        },
        setShowList() {
            let list = []
            let cellList = []
            let subObj = { title: '班次信息', cellList: cellList }
            cellList.push({label:'线路',prop:'routeNa'})
            cellList.push({label:'班次',prop:'default',isHidden:true})
            cellList.push({label:'日期',prop:'oDate'})
            cellList.push({label:'运行情况',prop:'eState',formatter:this.runStatusFil})
            cellList.push({label:'起点站',prop:'sSiteNa'})
            cellList.push({label:'终点站',prop:'eSiteNa'})
            cellList.push({label:'计划发车时间',prop:'p-dTime'})
            cellList.push({label:'实际发车时间',prop:'sTime'})
            // cellList.push({label:'车辆上线时间',prop:'default'})
            cellList.push({label:'结束时间',prop:'eTime'})
            cellList.push({label:'运行时长',prop:'runTime',unit:'(分钟)'})
            cellList.push({label:'时间依据',value:'GPS'})
            cellList.push({label:'计划司机',prop:'p-driverNa'})
            cellList.push({label:'实际司机',prop:'driverNa'})
            cellList.push({label:'实际工号',prop:'workNum'})
            cellList.push({label:'计划车牌号',prop:'p-vehicleNo'})
            cellList.push({label:'实际车牌号',prop:'vehicleNo'})
            cellList.push({label:'自编号',prop:'customNo'})
            cellList.push({label:'运行里程',prop:'runM',unit:'(km)'})
            cellList.push({label:'班次状态',prop:'rType',formatter:this.rTypeFil})
            // cellList.push({label:'日时刻表',prop:'default'})
            cellList.push({label:'是否跨线路',prop:'isCrossL',formatter:this.isCrossLFil})
            // cellList.push({label:'班次创建员',prop:'createNa'})
            cellList.push({label:'班次创建时间',prop:'createT'})
            cellList.push({label:'备注',prop:'remark'})
            list.push(subObj)
            /*------------------------------*/
            cellList = []
            subObj = {title:'路单操作信息',cellList:cellList}
            cellList.push({label:'路单操作人',prop:'createNa'})
            cellList.push({label:'路单操作时间',prop:'createT'})
            cellList.push({label:'路单操作备注',prop:'remark'})
            cellList.push({label:'路单审核人',prop:'verifyNa'})
            cellList.push({label:'路单审核时间',prop:'verifyT'})
            cellList.push({label:'路单审核状态',prop:'verify',formatter:this.auditStatusFil})
            cellList.push({label:'路单审核备注',prop:'verifyR'})
            list.push(subObj)

            this.showList = list
        },
        formatterValue(cell){
            let val = this.info[cell.prop]
            if (cell.formatter) {
                val = cell.formatter(val)
            }
            return val
        },
        getDetail() {
            let url = '/waybillActual/detail'
            let params = {
                waybillId: this.waybillId
            }
            this.$http.post(url, params).then(({detail}) => {
                if (detail) {
                    Object.keys(detail).forEach(key=>{
                        this.$set(this.info,`p-${key}`,detail[key])
                    })
                }
            })
        }
    }
}

</script>
<style lang="scss" scoped>
.title{
    font-weight: bold;
    margin:15px 0 8px;
}
.el-col{
    margin:4px 0;
    &.hidden{
        visibility: hidden;
    }
}
</style>