<template>
    <div class="map_pos_select">
        <jy-dialog :title="'选择经纬度'" :visible.sync="dialogVisible" custom-class="dialog_table" :width="global.dialogMapWidth">
            <div>
                位置：
                <!-- <el-input v-model="slocation" placeholder="请输入位置" id="suggestId"></el-input> -->
                <el-autocomplete v-model="slocation" :fetch-suggestions="getlocation" placeholder="请输入位置" id="suggestId"></el-autocomplete>
                <el-button type="primary" @click="search">查 询</el-button>
                <el-row class="row_box">
                    <el-col :span="8">
                        纬度：
                        <span v-text="lng"></span>
                    </el-col>
                    <el-col :span="8">
                        经度：
                        <span v-text="lat"></span>
                    </el-col>
                </el-row>
                <div id="map_pos_select" v-bind:style="mapStyle"></div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script type="text/javascript">
export default {
    data() {
        return {
            // 查询位置
            slocation: "",
            locationList: [],

            dialogVisible: false,
            lng: "",
            lat: "",
            mapStyle: {},
            type: '',
            address: ''
        };
    },
    activated() {
        this.setStyle();
        this.getlocation();
    },
    mounted() {
        this.setStyle();
    },

    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.lng = "";
                this.lat = "";
                this.slocation = "";
            }
        },
        lng: function (val) {
            let list = [];
            if (val && this.lat) {
                list.push({
                    lng: val,
                    lat: this.lat,
                });
            }
            this.setMarker(list);
        },
        lat: function (val) {
            let list = [];
            if (val && this.lng) {
                list.push({
                    lat: val,
                    lng: this.lng,
                });
            }
            this.setMarker(list);
        },
        // slocation: function(val) {
        //   console.log(val)
        //   this.getlocation()
        // }
    },
    methods: {
        getlocation(queryString, cb) {
            let url = "/site/info/GetBDMap";
            let option = {
                query: this.slocation,
                region: "全国",
                city_limit: "false",
            };
            this.$http.post(url, option).then((res) => {
                console.log(res);
                this.locationList = res.detail;
                if (this.locationList) {
                    this.locationList.forEach((item) => {
                        item.value = item.name;
                    });
                    var results = queryString
                        ? this.locationList.filter(
                              this.createFilter(queryString)
                          )
                        : this.locationList;
                    // 调用 callback 返回建议列表的数据
                    if (cb && cb instanceof Function) {
                        cb(results);
                    }
                } else {
                    if (cb && cb instanceof Function) {
                        cb([]);
                    }
                }
            });
        },
        createFilter(queryString) {
            return (state) => {
                return (
                    state.name
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) === 0
                );
            };
        },
        init(option,type) {
            this.dialogVisible = true;
            if(type) {
                this.type = type
            }
            if (option) {
                this.lng = option.lng;
                this.lat = option.lat;
                // if (!this.map) {
                this.loadScript(option);
                // }
            } else {
                // if (!this.map) {
                this.loadScript();
                // }
            }
        },
        setStyle() {
            this.$nextTick(() => {
                let obj = {
                    height: $(this.$el).width() / 3 + "px",
                };
                this.mapStyle = obj;
                console.log(this.mapStyle);
            });
        },
        loadScript(option) {
            var _this = this;
            let interval = setInterval(() => {
                console.log("loadScript");
                if (window.BMap) {
                    var ipLocal = function () {
                        var myCity = new BMap.LocalCity();
                        myCity.get((result) => {
                            console.log(result);
                            var cityName = result.name;
                            _this.map.setCenter(cityName);
                            let center = result.center;
                            _this.map.centerAndZoom(
                                new BMap.Point(center.lng, center.lat),
                                11
                            );
                        });
                    };
                    clearInterval(interval);
                    this.map = new window.BMap.Map("map_pos_select", {
                        maxZoom: 19,
                    });
                    if (option) {
                        this.map.centerAndZoom(
                            new BMap.Point(option.lng, option.lat),
                            19
                        );
                        let list = [
                            {
                                lng: option.lng,
                                lat: option.lat,
                            },
                        ];
                        this.setMarker(list);
                    } else {
                        ipLocal();
                    }

                    // var geolocation = new BMap.Geolocation();
                    // // 开启SDK辅助定位
                    // geolocation.enableSDKLocation();
                    // geolocation.getCurrentPosition(function(r) {
                    // 	if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                    // 		var mk = new BMap.Marker(r.point);
                    // 		_this.map.addOverlay(mk);
                    // 		_this.map.panTo(r.point);
                    // 	} else {
                    // 		ipLocal();
                    // 	}
                    // });
                    _this.map.enableScrollWheelZoom(true);
                    let geo = new BMap.Geocoder();
                    _this.map.addEventListener("click", function (e) {
                        _this.lng = e.point.lng;
                        _this.lat = e.point.lat;
                        geo.getLocation(e.point,function(res) {
                            _this.address = res.address
                        })
                    });

                    // 搜索引擎
                    var ac = new BMap.Autocomplete({
                        //建立一个自动完成的对象
                        input: "suggestId",
                        location: _this.map,
                    });
                    console.log(ac);
                    let myValue;
                    ac.addEventListener("onconfirm", function (e) {
                        //鼠标点击下拉列表后的事件
                        var _value = e.item.value;
                        myValue =
                            _value.province +
                            _value.city +
                            _value.district +
                            _value.street +
                            _value.business;
                        _this.slocation = myValue;
                    });
                }
            }, 1000);
        },
        setMarker(list) {
            //清除-----
            this.map.clearOverlays();
            list = list ? list : this.stationList;
            list.forEach((item) => {
                let point = new window.BMap.Point(item.lng, item.lat);
                let marker = new BMap.Marker(point);
                console.log(list);
                this.map.addOverlay(marker);
            });
        },
        cancel() {
            this.dialogVisible = false;
        },
        search() {
            // this.map.clearOverlays() //清空原来的标注
            var localSearch = new BMap.LocalSearch(this.map);
            localSearch.enableAutoViewport(); //允许自动调节窗体大小
            localSearch.setSearchCompleteCallback((searchResult) => {
                var poi = searchResult.getPoi(0);
                // document.getElementById('coordinate').value =
                //     poi.point.lng + ',' + poi.point.lat
                this.lng = poi.point.lng;
                this.lat = poi.point.lat;
                this.map.centerAndZoom(poi.point, 13);
                // var marker = new BMap.Marker(
                //     new BMap.Point(poi.point.lng, poi.point.lat)
                // ) // 创建标注，为要查询的地方对应的经纬度
                // this.map.addOverlay(marker)
                // var content =
                //     document.getElementById('text_').value +
                //     '<br/><br/>经度：' +
                //     poi.point.lng +
                //     '<br/>纬度：' +
                //     poi.point.lat
                // var infoWindow = new BMap.InfoWindow('<p>' + content + '</p>')
                // marker.addEventListener('click', function() {
                //     this.openInfoWindow(infoWindow)
                // })
                // marker.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画
            });
            localSearch.search(this.slocation);
        },
        save() {
            if (!this.lng || !this.lat) {
                this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    showClose: false,
                    type: "warning",
                });
                return;
            }
            this.$emit("getLngLat", this.lng, this.lat,this.type);
            this.$emit("getAddress",this.address,this.type);
            this.dialogVisible = false;
        },
    },
};
</script>
<style type="text/css" lang="scss" scoped="map_pos_select">
.map_pos_select {
    .el-autocomplete {
        display: inline-block;
        width: 250px;
        margin-right: 50px;
    }
    .row_box {
        margin-bottom: 20px;
    }
}
</style>
